import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import React from "react"

const Funct = () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({ id: "home.title" })}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <h1>
        <FormattedMessage id="home.title" />
      </h1>
      <p>
        <FormattedMessage id="welcome" />
      </p>
      <p>
        <FormattedMessage id="build" />
      </p>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        {/*<Image />*/}
      </div>
      <Link to="/page-2/">
        <FormattedMessage id="go_page2" />
      </Link>
    </Layout>
  )
}

export default Funct
